.custom-input .text-input {
  @apply block;
  @apply w-full;
  @apply rounded-md;
  @apply border-gray-300;
  @apply shadow-sm;
  @apply focus:border-5minds-blue-900;
  @apply focus:ring-5minds-blue-900;
  @apply sm:text-sm;
}

.custom-input .text-input-label {
  @apply block;
  @apply text-sm;
  @apply font-medium;
  @apply text-gray-700;
}
